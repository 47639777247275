import { getByAddress } from "./helper";

export const TableView = ({ field, translation, props, compValue }) => {
  let address = getByAddress(field.address);

  const value =
    typeof field?.value === "object"
      ? field?.value?.label || field?.value?.[address] || "-"
      : field.value;

  let topHeader = props?.["pdf-top-header"];

  return (
    <span className="break-inside container">
      <div  className="break-inside">
      <div style={styles.border_div} />

      {topHeader && (
        <div
          style={{
            backgroundColor: "#ebecf1",
            display: "flex",
            justifyContent: "center",
            height: 30,
            alignItems: "center",
            marginTop: 10,
          }}
        >
          {(() => {
            let f = field.address?.split?.(".");
            let index = f.reverse().find((x) => !isNaN(parseInt(x * 1)));

            return (
              <>
                {translation["Record"] || "Record"} # {parseInt(index) + 1}
              </>
            );
          })()}
        </div>
      )}
      <div style={styles.wrapper}>
        <div style={styles.title_container}>
          <p style={styles.title}>
            {translation[(field.title + "").trim()] ||
              translation[props?.["pdf-title"]] ||
              field.title ||
              props?.["pdf-title"]}
          </p>
        </div>

        <div style={styles.value_container}>
          {compValue?.(value) || translation[value] || value || "-"}
        </div>
      </div>
      <div style={styles.border_div} />
      </div>
    </span>
  );
};

const styles = {
  container: {
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    // justifyContent: "center",
    minHeight: 30,
    overflow: "hidden",
    borderRight: "1px solid #ebecf1", // Adding border to the right side
    borderLeft: "1px solid #ebecf1",
    alignItems: "stretch", // Ensure children stretch to the wrapper height
    padding: 1,
  },

  title_container: {
    width: "30%",
    padding: 10,
    backgroundColor: "#F6F8FA",
  },
  title: {
    margin: 0,
    fontSize: 12,
  },
  title_ar: {
    margin: 0,
    fontSize: 12,
    fontWeight: "bold",
  },
  value_container: {
    width: "50%",
    padding: 10,
    display: "flex", // Added flex display to center content vertically
    alignItems: "center", // Center content vertically
  },
  border_div: {
    height: "0.08px",
    marginTop:-0.1,
    backgroundColor: "#ebecf1",
  },
};
