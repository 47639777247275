import React, { useRef, useEffect, useState, Fragment, useMemo } from "react";
import cls from "classnames";
import SignatureCanvas from "react-signature-canvas";
import { Card as AntdCard, Button } from "antd";
import { observer } from "@formily/reactive-react";

const Signature = observer((props) => {
  const [parentWidth, setParentWidth] = useState(0);
  const parentRef = useRef(null);
  const canvasRef = useRef(null);

  const { value, onChange, translation } = props;

  const updatedValue = useMemo(() => {
    let _value = JSON.parse(JSON.stringify(value));
    let minValue =
      Math.min(...value.map((element) => element.map((cor) => cor.x)).flat()) -
      10;

    _value.forEach((element) =>
      element.forEach((cor) => {
        cor.x = cor.x - minValue;
      })
    );

    return _value;
  }, [value]);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    handleResize(); // Initial width calculation

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (canvasRef.current && updatedValue) {
      canvasRef.current.fromData(updatedValue);
    }
  }, [updatedValue, canvasRef.current]);

  const cssStyle = `
  .dn-signature {
      display: block;
      break-inside: avoid
      position: relative;
      width: 100%;
      overflow:"hidden"
    }

    .signature-wrapper {
      border-radius: 5px;
    
      width: 100%;
      height: 100px;
      overflow: hidden;
    
      &-canvas {
        width: 100%;
        height: 99px;
      }
    }
    `;
  let title = (props.title + "").replace("*", "");
  title = translation[title] || title;
  return (
    <>
      <style>{cssStyle}</style>
      <div
        dir="ltr"
        ref={parentRef}
        className="signature-wrapper break-inside"
        style={{
          backgroundColor: "#F6F8FA",
          // display: "flex",
          justifyContent: "center",
          height: 150,
          alignItems: "center",
          overflow: "hidden",
          marginTop: 10,
          flexDirection: "row",
          padding: 10,
        }}
      >
        <h4>{title}</h4>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ height: "100px", width: parentWidth }}
          ref={(ref) => {
            canvasRef.current = ref;
            canvasRef?.current?.off?.();
          }}
          clearOnResize={false}
        />
      </div>
    </>
  );
});
export default Signature;
