import React from "react";
import Logo1 from "../../../assets/aman_logo.png";
import "./style.css";
const AllLogos = {
  Aman: Logo1,
};

function HeaderObligation({
  formName,
  submissionInfo,
  translation,
  isReportNumber = true,
  isInspectionType = true,
}) {
  const params = new URLSearchParams(new URL(window.location.href).search);
  const projectName = params.get("project");

  const Logo = AllLogos[projectName];

  return (
    <>
      {projectName !== "Aman" && (
        <div className="pdf-header-temp" dir="ltr">
          <div>
            {Logo && (
              <div>
                <img alt="" width="100%" src={Logo1} />
              </div>
            )}
          </div>
          <div
            className="detail-section-obl"
            dir="rtl"
            style={{
              width: 180,
            }}
          >
            <p style={{ margin: 0 }}>
              {translation[submissionInfo.inspection_type] ||
                submissionInfo.inspection_type}
            </p>
            <p style={{ margin: 0 }}>{translation[formName] || formName}</p>
          </div>
        </div>
      )}

      <div className="head-bottom-info">
        {projectName === "Aman" && (
          <div>
            {submissionInfo?.data?.region?.label && (
              <p className="head-bottom-info-text">
                {translation[submissionInfo.data.region.label] ||
                  submissionInfo.data.region.label}
              </p>
            )}

            {submissionInfo?.site?.name_ar && (
              <p className="head-bottom-info-text">
                {submissionInfo.site.name_ar}
              </p>
            )}

            {submissionInfo?.site?.cluster_name && (
              <p className="head-bottom-info-text">
                {translation[submissionInfo.site.cluster?.name] ||
                  submissionInfo.site.cluster?.name}
              </p>
            )}
          </div>
        )}

        <div dir="ltr">
          {isReportNumber && (
            <>
              <p className="head-bottom-info-bold-text">
                <strong> Report Number</strong>
                <span style={{ fontSize: 12, fontWeight: 100 }}>
                  {` `} |{" "}
                  {"0".repeat(
                    6 -
                      String(
                        isNaN(submissionInfo.report_no * 1)
                          ? 0
                          : submissionInfo.report_no
                      ).length
                  ) + submissionInfo.report_no}
                </span>
              </p>

              <p className="head-bottom-info-bold-text">
                <strong>Date</strong>
                <span style={{ fontSize: 12, fontWeight: 100 }}>
                  {` `} | {new Date(submissionInfo.created_at).toDateString()}
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default HeaderObligation;
